<template>
  <div class="composite-detail">
    <div class="composite">
      <div class="collection-top col-center">
        <div
          class="row-center"
          :style="'width:100%'"
        >
          <goods-item
            :goodsType="data.mergeTokenConfigDto.tokenCategory ? mediaType[data.mergeTokenConfigDto.tokenCategory] : 'img'"
            :goodsSrc="data.mergeTokenConfigDto.coverUrl"
            :goodsSource="data.mergeTokenConfigDto.sourceFile"
            :goodsPoster="data.mergeTokenConfigDto.coverUrl"
            :goodsBackground="data.mergeTokenConfigDto.backgroundImg"
          ></goods-item>
        </div>

      </div>
      <div class="infos">
        <div class="infos-1">
          <div class="name-l">
            <img
              src="../assets/icon/composite/deco_left.png"
              alt=""
            >
          </div>
          <div class="name">
            <p>
              {{data.mergeTokenConfigDto.displayName}}
            </p>
          </div>
          <div class="name-r">
            <img
              src="../assets/icon/composite/deco_right.png"
              alt=""
            >
          </div>
        </div>
        <div class="infos-2">
          <div class="count">
            <div class="count-l">
              <img
                src="../assets/icon/composite/limit.png"
                alt=""
              >
              <p>限量</p>
            </div>
            <div class="count-r">
              <p>{{data.mergeTokenConfigDto.limitMessage}}</p>
            </div>
          </div>
          <div class="count">
            <div class="count-l">
              <img
                src="../assets/icon/composite/limit.png"
                alt=""
              >
              <p>每人</p>
            </div>
            <div class="count-r">
              <p>{{data.mergeTokenConfigDto.limitationFlag == 1 ? `${data.mergeTokenConfigDto.limitationNum}份` : '不限'}}</p>
            </div>
          </div>
        </div>
        <div class="infos-3">
          <div class="open-l"></div>
          <!-- <div
            class="open"
            v-if="data.mergeTokenConfigDto.activityStartTime"
          >
            <span>开放时间：</span>
            <span class="time">{{data.mergeTokenConfigDto.activityStartTime | formatDate}}</span>
          </div> -->
          <div class="open-r"></div>
        </div>

      </div>
      <div class="material">
        <div class="title">合成材料</div>
        <!-- 轮播图 -->
        <swiper
          class="my-swipe"
          ref="banner"
          @slide-change="onChange"
          :options="swiperOption"
        >
          <swiper-slide
            v-for="(item,index) in materialList"
            :key="index"
            class="good-card"
          >
            <p class="select-composite-material-title">方案{{ index + 1 }}</p>
            <div
              v-for="(i,j) in item.planList"
              :key="j"
              class="select-composite-material-item row-start"
            >
              <img
                class="select-composite-material-item-img"
                :src="i.image"
              />
              <div class="col-start">
                <p class="select-composite-material-item-name">{{ i.name }}</p>
                <p class="select-composite-material-item-amount">{{i.amount}}份</p>
              </div>
            </div>
            <div
              v-if="selectIndex == index"
              class="row-center completed-composite-button-select"
              @click="selectComposite"
            >
              <img
                class="completed-composite-button-select-right"
                src="../assets/icon/composite/right.png"
              />
              选择该方案
            </div>
            <div
              v-else
              :class="`${canComposite ? 'completed-composite-button' : 'disabled-composite-button'}`"
              class="row-center"
              @click="selectComposite"
            >
              选择该方案
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="tips">
        <div class="title">合成规则</div>
        <div
          class="rules"
          v-html="data.mergeTokenConfigDto.mergeDesc"
        >
          <!-- <p>《繁星》系列合成说明： </p>
          <p>1:集齐《繁星》系列8款藏品，可合成《璀璨》数字藏品1份； </p>
          <p>2:《璀璨》合成数量999份，每个UID最多合成2份； </p>
          <p>3:确认合成后，使用的合成材料将合成完成后销毁； </p>
          <p>4:合成开放时间：2022.01.02 12:30 </p>
          <p>5:《璀璨》全部合成完毕平台将关闭该系列藏品合成通道。</p> -->
        </div>
      </div>
      <div
        style="z-index:1999;"
        :class="`${!showMaterialNorEnoughTips ? 'btns' : 'btns-disabled'}`"
      >
        <!-- disabled-composite completed-pay-->
        <p v-if="showMaterialNorEnoughTips">合成材料不足，无法合成</p>
        <div
          :class="`${canComposite ? 'completed-pay' : 'disabled-composite'}`"
          @click="toComposite"
        >{{btnText}}</div>

      </div>
    </div>
    <!-- 账户是否认证弹窗 -->
    <el-dialog
      title="账户未实名认证"
      :visible.sync="authVisible"
      @confirm="$router.push('/certification')"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="authVisible = false">取 消</el-button>
        <div
          @click="goCertification"
          class="confirm-button row-center"
        >去认证</div>
      </span>
    </el-dialog>
    <!-- 提示弹窗 -->
    <el-dialog
      :visible.sync="browerOpenVisible"
      @confirm="browerOpenVisible = false"
      class="donation-dialog"
      center
    >
      <p
        style="width: 90% !important;margin-left: 5%;"
        class="donation-error-tip-title"
      >{{ goodBuyTitle }}</p>
      <p
        style="width: 90% !important;margin-left: 5%;"
        class="donation-error-title"
      >{{ goodBuyTip }}</p>
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <div
          @click="browerOpenVisible = false"
          class="donation-button row-center"
        >确定</div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from '../api/index-client'
import Cookies from 'js-cookie'
import GoodsItem from '../components/GoodsItem.vue'
import config from '../config'
import { Swipe,SwipeItem } from "vant";
var vm = null
export default {
  components: {
    GoodsItem,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem
  },
  data () {
    return {
      swiperOption: {
        observer: true, // 修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, // 修改swiper的父元素时，自动初始化swiper
        slidesPerView: 'auto',
        clicks: {
          preventClicksPropagation: false,
        },
        centeredSlides: true,
        spaceBetween: 7,
        loopAdditionalSlides: 100,
        on: {
        }
      },
      browerOpenVisible: false,
      goodBuyTitle: '',
      goodBuyTip: '',
      authVisible: false,
      list: [1,2,3,4],
      data: {
        mergeTokenConfigDto: {
          mergePlanList: [
            {
              planList: []
            }
          ]
        },
        skuCountBySku: {},
        toMergeSkuVo: {},
      },
      planIndex: 0,
      selectIndex: null,
      materialList: [],
      collectionInfo: {
        image: '',
        name: '',
        skuNo: '',
        uname: '',
        uniqueCode: '',
        facImg: '',
        facName: '',
        issueTime: '',
        chainStatus: '',
      },
      mediaType: config.mediaType,
      canComposite: true,
      showMaterialNorEnoughTips: false,
      btnText: '立即合成',
      isAuth: true
    }
  },
  computed: {
    getId () {
      return this.$route.query.id || ''
    }
  },
  mounted () {
    this.$store.commit('HIDE_SMNE')
    this.getData()
    this.getMyInfo()

  },
  methods: {
    // pico端提示
    goCertification () {
      if (Cookies.get('Device') == 'pico') {
        this.authVisible = false
        this.goodBuyTip = '请前往手机端完成实名认证'
        this.browerOpenVisible = true
      } else {
        this.$router.push('/certification')
      }
    },
    // 选择方案
    selectComposite () {
      if (this.canComposite) {
        this.selectIndex = this.planIndex
      }
    },
    // 切换轮播图
    onChange () {
      this.planIndex = this.$refs.banner.swiper.activeIndex
      if (this.data.mergeTokenConfigDto.periodFlag == 1 && this.data.mergeTokenConfigDto.activityStartTime && (new Date().getTime() < new Date(this.data.mergeTokenConfigDto.activityStartTime.replace(/-/g,'/')).getTime())) {
        if (new Date().getTime() < new Date(this.data.mergeTokenConfigDto.activityStartTime.replace(/-/g,'/')).getTime()) {
          this.btnText = `${this.data.mergeTokenConfigDto.activityStartTime}开放`
          this.canComposite = false
        }
      } else if (this.data.mergeTokenConfigDto.periodFlag == 1 && this.data.mergeTokenConfigDto.activityEndTime && (new Date().getTime() > new Date(this.data.mergeTokenConfigDto.activityEndTime.replace(/-/g,'/')).getTime())) {
        if (new Date().getTime() > new Date(this.data.mergeTokenConfigDto.activityEndTime.replace(/-/g,'/')).getTime()) {
          this.btnText = '已结束'
          this.canComposite = false
        }
      } else {
        if (this.materialList[this.$refs.banner.swiper.activeIndex].planList.filter(i => this.data.skuCountBySku[i.skuNo] < i.amount).length) {
          this.canComposite = false
          this.showMaterialNorEnoughTips = true
          this.$store.commit('SHOW_SMNE')
        } else {
          this.canComposite = true
          this.showMaterialNorEnoughTips = false
        }
      }

    },
    // 获取个人基本信息
    getMyInfo () {
      api
        .get('userinfo')
        .then(result => {
          if (result.data.success) {
            if (result.data.data.hasRealname) {
              this.$store.commit('HIDE_ISAUTH')
            } else {
              this.$store.commit('SHOW_ISAUTH')
            }
            this.isAuth = result.data.data.hasRealname
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
          this.$store.commit('HIDE_APPLOADING')
        })
    },
    getData () {
      api
        .get(`mall/mergetoken/config/${this.getId}`)
        .then(res => {
          if (res.data.status == 200) {
            this.data = res.data.data
            this.materialList = res.data.data.mergeTokenConfigDto.mergePlanList ? res.data.data.mergeTokenConfigDto.mergePlanList : []
            if (this.data.mergeTokenConfigDto.periodFlag == 1 && this.data.mergeTokenConfigDto.activityStartTime) {
              if (new Date().getTime() < new Date(this.data.mergeTokenConfigDto.activityStartTime.replace(/-/g,'/')).getTime()) {
                this.btnText = `${this.data.mergeTokenConfigDto.activityStartTime}开放`
                this.canComposite = false
              }
            }
            if (this.data.mergeTokenConfigDto.periodFlag == 1 && this.data.mergeTokenConfigDto.activityEndTime) {
              if (new Date().getTime() > new Date(this.data.mergeTokenConfigDto.activityEndTime.replace(/-/g,'/')).getTime()) {
                this.btnText = '已结束'
                this.canComposite = false
              }
            }
            if (this.materialList[0].planList.filter(i => this.data.skuCountBySku[i.skuNo] < i.amount).length) {
              this.canComposite = false
              this.showMaterialNorEnoughTips = true
              this.$store.commit('SHOW_SMNE')
            } else {
              this.canComposite = true
              this.showMaterialNorEnoughTips = false
            }
          } else {
            this.$toast({
              message: res.data.msg,
              icon: require('../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 开始倒计时
    startInterval () {

    },
    toComposite () {
      if (this.canComposite) {
        if (this.isAuth) {
          this.$router.push(`/compositing?id=${this.getId}&planId=${this.materialList[this.planIndex].planId}`)
        } else {
          this.authVisible = true
        }

      }

    }
  }
}
</script>

<style lang="less" scoped>
.composite-detail {
  margin-top: 3.875rem;
  padding-bottom: 4.875rem;
  .collection-top {
    width: 100%;
    height: auto;
    min-height: 15.625rem;
    background: #d0d0d0;
    // padding-bottom: 1.1875rem;
  }
  .infos {
    margin: 1.4375rem 0.8125rem;
    color: #ffffff;
    &-1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 0.75rem;
      .name-l,
      .name-r {
        width: 2.625rem;
        height: 1.1875rem;
        flex-grow: 0;
      }
      .name {
        flex-grow: 1;
        p {
          width: 100%;
          text-align: center;
          font-size: 1.1875rem;

          font-weight: bold;
        }
      }
    }
    &-2 {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1rem 0 1.4375rem;
      .count {
        display: flex;
        // margin-bottom: 0.9375rem;
        font-size: 0.75rem;
        &:last-child {
          margin-left: 0.75rem;
        }
        .count-l {
          // width: 3.125rem;
          padding: 2px 5px;
          height: 1.25rem;
          line-height: 1.25rem;
          background: #f3cdb6;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #010804;
          p {
            margin-left: 2px;
          }
        }
        .count-r {
          // width: 3.75rem;
          padding: 2px 5px;
          height: 1.25rem;
          line-height: 1.25rem;
          background: #484848;
          border-radius: 0px 4px 4px 0px;
          color: #f3cdb6;
          text-align: center;
        }
      }
      // .count2 {
      //   .count-l {
      //   }
      //   .count-r {
      //   }
      // }
    }
    .infos-3 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .open-l,
      .open-r {
        width: 4.0625rem;
        height: 2px;
        background: #3a3a3a;
      }
      .open {
        font-size: 0.875rem;
        color: #cccccc;
        text-align: center;
        .time {
          // margin-left: 0.5rem;
        }
      }
    }
  }
  .material {
    // margin: 0 0.8125rem;
    border-radius: 0.5rem;
    color: #ffffff;
    background: #1c1c1c;
    .title {
      text-align: center;
      font-size: 1.125rem;
      font-weight: bold;
    }
    .list {
      // margin-top: ;
      display: flex;
      justify-content: start;
      flex-wrap: wrap;
      width: 340px;
      margin: 0.875rem auto 0;
      .item {
        width: 10rem;
        height: 12.5rem;
        border-radius: 0.375rem;
        overflow: hidden;
        margin: 0 9px 9px 0;
        position: relative;
        .icon {
          border-radius: 0.375rem 0.375rem 0px 0px;
          width: 10rem;
          height: 10rem;
          background: #ffffff;
          position: relative;
          &-container {
            width: 10rem;
            height: 10rem;
            display: block;
            position: relative;
            img {
              max-width: 100%;
              max-height: 100%;
              width: auto;
              height: auto;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate3d(-50%, -50%, 0);
            }
          }
          .icon-modal {
            width: 10rem;
            height: 10rem;
            background: rgba(0, 0, 0, 0.6);
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        .name {
          height: 2.5rem;
          line-height: 2.5rem;
          padding-left: 0.75rem;
          text-align: left;
          font-size: 1rem;
          background: #0c0d0d;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .no {
          width: 1.875rem;
          height: 1.5rem;
          background: #000000;
          border-radius: 0px 6.25rem 6.25rem 0px;
          opacity: 0.65;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
          font-size: 0.75rem;
          position: absolute;
          left: 0;
          top: 0.9375rem;
        }
      }
    }
  }
  .tips {
    margin: 0.75rem 0.8125rem 1.4375rem;
    border-radius: 0.5rem;
    color: #cccccc;
    background: #2c2c2c;
    padding: 1rem 0 0 0;
    padding-bottom: 1rem;
    .title {
      text-align: center;
      font-size: 1.125rem;
      font-weight: bold;
      color: #ffffff;
      margin-bottom: 0.5rem;
    }
    .rules {
      padding: 0 0.5625rem;
      /deep/ img {
        max-width: 100%;
        display: block;
        height: auto;
      }
    }
    p,
    div {
      // margin-left: 0.5625rem;
      font-size: 0.875rem;
      font-family: NotoSansHans-Regular, NotoSansHans;
      font-weight: 400;
      color: #cccccc;
      line-height: 1.5rem;
    }
  }

  .btns,
  .btns-disabled {
    // width: 375px;
    height: 3.3125rem;
    background: #080808;
    padding-top: 0.4375rem;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    p {
      position: absolute;
      text-align: center;
      top: 0rem;
      left: 50%;
      transform: translateX(-50%);
      color: #ffffff;
      font-size: 0.75rem;
      margin: 0.3rem auto;
    }
    .completed-pay {
      width: 19.375rem;
      height: 2.875rem;
      margin: 0 auto;
      line-height: 2.875rem;
      backdrop-filter: blur(1.1875rem);
      text-align: center;
      font-size: 1rem;
      font-weight: normal;
      color: #ffffff;
    }
    .disabled-composite {
      width: 19.375rem;
      height: 2.875rem;
      margin: 0 auto;
      line-height: 2.875rem;
      backdrop-filter: blur(1.1875rem);
      text-align: center;
      font-size: 1rem;
      font-weight: normal;
      color: #ffffff;
    }
  }
  .btns-disabled {
    height: 4.875rem !important;
    .completed-pay,
    .disabled-composite {
      width: 19.375rem;
      height: 2.875rem;
      margin: 1.3rem auto;
      line-height: 2.875rem;
      backdrop-filter: blur(1.1875rem);
      text-align: center;
      font-size: 1rem;
      font-weight: normal;
      color: #ffffff;
    }
  }
  .good-card {
    position: relative;
    width: 80%;
    // margin: 0.75rem 3% 0 3%;
    border-radius: 0.5rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  // .swiper-wrapper .good-card :first-child {
  //   // margin-left: 10%;
  // }
  .swiper-container {
    height: auto;
    overflow-y: auto;
    margin-top: 20px;
    .swiper-slide {
      background: #2c2c2c;
    }
    // /* 上一张 */
    // .swiper-slide-prev {
    //   margin-right: 7px;
    //   height: auto !important;
    // }
    // /* 下一张 */
    // .swiper-slide-next {
    //   margin-right: 7px;
    //   height: auto !important;
    // }

    /* 中间的图片 */
    .swiper-slide-active {
      height: auto !important;
    }
    .order-menu-index {
      top: 0;
      width: 96vw;
      margin-left: 3vw;
      position: inherit;
      background: transparent !important;
      // .el-menu {
      //   background: transparent !important;
      //   background-color: transparent !important;
      // }
      .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
      .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
      .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
        background: transparent !important;
        background-color: transparent !important;
      }
    }
  }
  .select-composite-material-title {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #ffffff;
    margin: 20px 0;
  }
  .select-composite-material-item {
    width: 90%;
    height: 96px;
    background: #1c1c1c;
    margin-bottom: 10px;
    border-radius: 4px;
    .select-composite-material-item-img {
      width: 73px;
      height: 73px;
      margin: 0 10px;
      border-radius: 4px;
    }
    .select-composite-material-item-name {
      font-size: 13px;
      font-family: Noto Sans S Chinese;
      font-weight: 400;
      color: #fefefe;
      margin-bottom: 15px;
    }
    .select-composite-material-item-amount {
      font-size: 12px;
      font-family: Noto Sans S Chinese;
      font-weight: 400;
      color: #cccccc;
    }
  }
  .completed-composite-button {
    width: 150px;
    height: 50px;
    background-image: url('../assets/icon/confirm-button.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    background-size: cover;
    background-color: transparent;
    font-size: 1rem;
    font-family: lantingheiweight;
    font-weight: normal;
    color: #ffffff;
    line-height: 1.375rem;
    border-radius: 0;
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 1.6875rem;
    border-radius: 4px;
  }
  .disabled-composite-button {
    width: 150px;
    height: 50px;
    background-image: url('../assets/icon/composite/disabled-btn.png');
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    background-size: cover;
    background-color: transparent;
    font-size: 1rem;
    font-family: lantingheiweight;
    font-weight: normal;
    color: #ffffff;
    line-height: 1.375rem;
    border-radius: 0;
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 1.6875rem;
    border-radius: 4px;
  }
}
.my-swipe {
  width: 100vw;
}
.completed-composite-button-select {
  width: 150px;
  height: 50px;
  background-image: url('../assets/icon/composite/select_button.png');
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 0% 0%;
  background-size: cover;
  background-color: transparent;
  font-size: 1rem;
  font-family: lantingheiweight;
  font-weight: normal;
  color: #ffffff;
  line-height: 1.375rem;
  border-radius: 0;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 1.6875rem;
  border-radius: 4px;
}
.completed-composite-button-select-right {
  width: 16px;
  height: auto;
  margin-right: 2px;
}
</style>